import React, { useState, useEffect, useCallback } from 'react';
import { NavLink } from 'react-router-dom';
import { Link } from 'react-scroll';
import '../css/reset.css';
import '../css/footer.css';


const Footer = () => {


    const [VW, setVW] = useState(window.innerWidth);
    const handleResize = () => {
        setVW(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return(
        <>
            <section className="footer_wrap">
                <div className="footer_inner">
                    
                    <div className="footer_title_wrap">
                        <span></span>
                    </div>

                    <div className="footer_menu_wrap">

                        <div className="footer_contact_address">
                            <span>서울특별시 강남구 봉은사로 429, 102호(삼성동, 위즈빌딩)</span>
                            <span>대표자 이미영 / 사업자등록번호 146-87-01102</span>
                        </div>

                        <div className="footer_contact_wrap">
                            <div>
                                <span className="footer_contact_phone_wrap">
                                    <span className='footer_contact_phone' />
                                    <a href={`tel: 070-8655-2150`}>070-8655-2150</a>
                                </span>
                                <span className="footer_contact_email_wrap">
                                    <span className="footer_contact_email" />
                                    <a href={`mailto: office@keyonbit.com`}>office@keyonbit.com</a>
                                </span>
                            </div>
                            <div></div>
                        </div>
                    </div>

                    <div className="footer_border"></div>

                    <div className="footer_copyright_wrap">
                        {
                            VW >= 768 ? (
                                <>
                                    <span className="footer_copyright">copyright@2023 DATAFIVE. ALL Right Reserved.</span>
                                    <div className="footer_madeby_wrap">
                                        <span className="footer_madeby_text">MADE BY</span>
                                        <span className="keyonbit_logo"></span>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className="footer_madeby_wrap">
                                        <span className="footer_madeby_text">MADE BY</span>
                                        <span className="keyonbit_logo"></span>
                                    </div>
                                    <span className="footer_copyright">copyright@2023 DATAFIVE. ALL Right Reserved.</span>
                                </>
                            )
                        }
                        
                    </div>

                </div>
            </section>
        </>
    );
}

export default Footer;