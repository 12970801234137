import React from "react";
import { BrowserRouter, Route, Routes, Link } from 'react-router-dom'; // HashRouter as Router
import Main from './component/Main';

const App = () => {
  return(
    <div className='datafiveApp'>
		<BrowserRouter>
			<Routes>
				<Route path="/" element={<Main />}></Route>
				<Route path="*" element={<Main />}></Route>
			</Routes>
		</BrowserRouter>
	</div>
  );
}

export default App;
