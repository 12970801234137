import React, { useRef, useState, useEffect } from 'react';
import '../css/reset.css';
import '../css/process.css';


const Process = () => {

    const [VW, setVW] = useState(window.innerWidth);
    var viewPort = (648 / 768) * VW;
    const handleResize = () => {
        setVW(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return (
        <>
            <section
                className="process_wrap"
                id="processSection"
                name="processSection"
                style={
                    VW >= 1440 ? {} : VW >= 768 ? {
                        paddingLeft: (60 / 768) * VW,
                        paddingRight: (60 / 768) * VW,
                        backgroundPosition: '-370px',
                    } : {}
                } // 768 1100     1360  1500
            >
                <div className="process_inner" style={{ width: VW >= 1440 ? '' : VW >= 768 ? viewPort : '' }}>

                    <div className="process_title_wrap">
                        <h3>PROCESS</h3>
                        <strong>서비스 절차</strong>
                    </div>

                    <div className="process_produce_wrap">
                        <p>고객님의 의뢰는 아래와 같은 절차로 진행됩니다.</p>
                    </div>

                    <div
                        className="process_content_wrap"
                        style={
                            VW >= 1440 ? {} : VW >= 768 ? {
                                width: (550 / 768) * VW
                            } : { width: '280px' }
                        }
                    >

                        {
                            VW >= 1440 ? (
                                <>
                                    <div className="process_box process_type1" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>1</span>
                                        </div>
                                        <div className="process_box_img_frame process_1">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>의뢰하기</span>
                                        </div>

                                        <span
                                            className="process_arrow"
                                            style={
                                                VW >= 1440 ? {} :
                                                    VW > 1000 ? { right: ((-((550 / 768) * VW) / 4) + 140) } :
                                                        VW >= 768 ? { right: -(42 / 768) * VW } : {}
                                            }

                                        />
                                    </div>

                                    <div className="process_box process_type2" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>2</span>
                                        </div>
                                        <div className="process_box_img_frame process_2">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>상담</span>
                                        </div>

                                        <span
                                            className="process_arrow"
                                            style={
                                                VW >= 1440 ? {} :
                                                    VW > 1000 ? { right: ((-((550 / 768) * VW) / 4) + 140) } :
                                                        VW >= 768 ? { right: -(42 / 768) * VW } : {}
                                            }
                                        />
                                    </div>

                                    <div className="process_box process_type3" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>3</span>
                                        </div>
                                        <div className="process_box_img_frame process_3">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>소요시간<br />비용안내</span>
                                        </div>

                                        <span
                                            className="process_arrow"
                                            style={
                                                VW >= 1440 ? {} :
                                                    VW > 1200 ? { right: ((-((550 / 768) * VW) / 64) + 100) } :
                                                        VW > 1000 ? { right: (60 / 768) * VW } :
                                                            VW >= 768 ? { right: (60 / 768) * VW } : {}
                                            }
                                        />
                                    </div>

                                    {
                                        VW >= 1440 ? (
                                            <></>
                                        ) : (
                                            <>
                                                <div className="process_type_line"></div>
                                            </>
                                        )
                                    }

                                    <div className="process_box process_type4" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>4</span>
                                        </div>
                                        <div className="process_box_img_frame process_4">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>개발진행</span>
                                        </div>

                                        <span
                                            className="process_arrow"
                                            style={
                                                VW >= 1440 ? {} :
                                                    VW > 1000 ? { right: ((-((550 / 768) * VW) / 4) + 140) } :
                                                        VW >= 768 ? { right: -(42 / 768) * VW } : {}
                                            }
                                        />
                                    </div>

                                    <div className="process_box process_type5" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>5</span>
                                        </div>
                                        <div className="process_box_img_frame process_5">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>테스트/<br />피드백</span>
                                        </div>

                                        <span
                                            className="process_arrow"
                                            style={
                                                VW >= 1440 ? {} :
                                                    VW > 1000 ? { right: ((-((550 / 768) * VW) / 4) + 140) } :
                                                        VW >= 768 ? { right: -(42 / 768) * VW } : {}
                                            }
                                        />
                                    </div>

                                    <div className="process_box process_type6" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>6</span>
                                        </div>
                                        <div className="process_box_img_frame process_6">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>최종산출물<br />전달</span>
                                        </div>
                                    </div>
                                </>
                            ) : VW >= 768 ? (
                                <>
                                    <div className="process_box process_type1" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>1</span>
                                        </div>
                                        <div className="process_box_img_frame process_1">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>의뢰하기</span>
                                        </div>

                                        <span
                                            className="process_arrow"
                                            style={
                                                VW >= 1440 ? {} :
                                                    VW > 1000 ? { right: ((-((550 / 768) * VW) / 4) + 140) } :
                                                        VW >= 768 ? { right: -(42 / 768) * VW } : {}
                                            }

                                        />
                                    </div>

                                    <div className="process_box process_type2" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>2</span>
                                        </div>
                                        <div className="process_box_img_frame process_2">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>상담</span>
                                        </div>

                                        <span
                                            className="process_arrow"
                                            style={
                                                VW >= 1440 ? {} :
                                                    VW > 1000 ? { right: ((-((550 / 768) * VW) / 4) + 140) } :
                                                        VW >= 768 ? { right: -(42 / 768) * VW } : {}
                                            }
                                        />
                                    </div>

                                    <div className="process_box process_type3" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>3</span>
                                        </div>
                                        <div className="process_box_img_frame process_3">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>소요시간<br />비용안내</span>
                                        </div>

                                        <span
                                            className="process_arrow"
                                            style={
                                                VW >= 1440 ? {} :
                                                    VW > 1200 ? { right: ((-((550 / 768) * VW) / 64) + 100) } :
                                                        VW > 1000 ? { right: (60 / 768) * VW } :
                                                            VW >= 768 ? { right: (60 / 768) * VW } : {}
                                            }
                                        />
                                    </div>

                                    {
                                        VW >= 1440 ? (
                                            <></>
                                        ) : (
                                            <>
                                                <div className="process_type_line"></div>
                                            </>
                                        )
                                    }

                                    <div className="process_box process_type6" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>6</span>
                                        </div>
                                        <div className="process_box_img_frame process_6">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>최종산출물<br />전달</span>
                                        </div>
                                    </div>

                                    <div className="process_box process_type5" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>5</span>
                                        </div>
                                        <div className="process_box_img_frame process_5">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>테스트/<br />피드백</span>
                                        </div>

                                        <span
                                            className="process_arrow"
                                            style={
                                                VW >= 1440 ? {} :
                                                    VW > 1000 ? { left: ((-((550 / 768) * VW) / 4) + 140) } :
                                                        VW >= 768 ? { left: -(42 / 768) * VW } : {}
                                            }
                                        />
                                    </div>

                                    <div className="process_box process_type4" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>4</span>
                                        </div>
                                        <div className="process_box_img_frame process_4">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>개발진행</span>
                                        </div>

                                        <span
                                            className="process_arrow"
                                            style={
                                                VW >= 1440 ? {} :
                                                    VW > 1000 ? { left: ((-((550 / 768) * VW) / 4) + 140) } :
                                                        VW >= 768 ? { left: -(42 / 768) * VW } : {}
                                            }
                                        />
                                    </div>

                                </>
                            ) : (
                                <>
                                    <div className="process_box process_type1" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>1</span>
                                        </div>
                                        <div className="process_box_img_frame process_1">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>의뢰하기</span>
                                        </div>

                                        <span
                                            className="process_arrow"
                                            style={
                                                VW >= 1440 ? {} :
                                                    VW > 1000 ? { right: ((-((550 / 768) * VW) / 4) + 140) } :
                                                        VW >= 768 ? { right: -(42 / 768) * VW } : {}
                                            }

                                        />
                                    </div>

                                    <div className="process_box process_type2" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>2</span>
                                        </div>
                                        <div className="process_box_img_frame process_2">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>상담</span>
                                        </div>

                                        <span
                                            className="process_arrow"
                                            style={
                                                VW >= 1440 ? {} :
                                                    VW > 1000 ? { right: ((-((550 / 768) * VW) / 4) + 140) } :
                                                        VW >= 768 ? { right: -(42 / 768) * VW } : {}
                                            }
                                        />
                                    </div>

                                    <div className="process_box process_type4" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>4</span>
                                        </div>
                                        <div className="process_box_img_frame process_4">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>개발진행</span>
                                        </div>

                                        <span
                                            className="process_arrow"
                                            style={
                                                VW >= 1440 ? {} :
                                                    VW > 1000 ? { right: ((-((550 / 768) * VW) / 4) + 140) } :
                                                        VW >= 768 ? { right: -(42 / 768) * VW } : {}
                                            }
                                        />
                                    </div>

                                    <div className="process_box process_type3" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>3</span>
                                        </div>
                                        <div className="process_box_img_frame process_3">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>소요시간<br />비용안내</span>
                                        </div>

                                        <span
                                            className="process_arrow"
                                            style={
                                                VW >= 1440 ? {} :
                                                    VW > 1200 ? { right: ((-((550 / 768) * VW) / 64) + 100) } :
                                                        VW > 1000 ? { right: (60 / 768) * VW } :
                                                            VW >= 768 ? { right: (60 / 768) * VW } : {}
                                            }
                                        />
                                    </div>

                                    <div className="process_box process_type5" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>5</span>
                                        </div>
                                        <div className="process_box_img_frame process_5">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>테스트/<br />피드백</span>
                                        </div>

                                        <span
                                            className="process_arrow"
                                            style={
                                                VW >= 1440 ? {} :
                                                    VW > 1000 ? { right: ((-((550 / 768) * VW) / 4) + 140) } :
                                                        VW >= 768 ? { right: -(42 / 768) * VW } : {}
                                            }
                                        />
                                    </div>

                                    <div className="process_box process_type6" style={VW >= 1440 ? {} : VW >= 768 ? { width: (150 / 768) * VW, height: (240 / 768) * VW } : {}}>
                                        <div className="process_box_frame">
                                            <span>6</span>
                                        </div>
                                        <div className="process_box_img_frame process_6">
                                            <span></span>
                                        </div>
                                        <div className="prcess_box_title">
                                            <span>최종산출물<br />전달</span>
                                        </div>
                                    </div>
                                </>
                            )
                        }


                    </div>

                    <div className="process_footer_wrap">

                        <div className="process_footer_title_wrap">
                            <ul>
                                <li className="process_footer_title_list list_title_1">
                                    <div><span>1</span></div>
                                    <span className='process_list_txt'>
                                        <strong>의뢰하기</strong>
                                        <p><span>:</span>하단에 간단한 문의하기 양식을 통해 3분안에 의뢰가 가능합니다.</p>

                                    </span>
                                </li>
                                <li className="process_footer_title_list list_title_2">
                                    <div><span>2</span></div>
                                    <span className='process_list_txt'>
                                        <strong>상담</strong>
                                        <p><span>:</span>DATAFIVE의 전문 컨설턴트가 정확한 커뮤니케이션으로 상담해드립니다.</p>

                                    </span>

                                </li>
                                <li className="process_footer_title_list list_title_3">
                                    <div><span>3</span></div>

                                    <span className='process_list_txt'>
                                        <strong>소요시간/비용안내</strong>
                                        <p><span>:</span>합리적이고 투명한 비용을 제시해드립니다.</p>
                                    </span>

                                </li>
                                <li className="process_footer_title_list list_title_4">
                                    <div><span>4</span></div>

                                    <span className='process_list_txt'>
                                        <strong>개발진행</strong>
                                        <p><span>:</span>상담한 내용을 토대로 작업 전, 한번 더 승인된 사항을 재확인 후 개발을 진행합니다.</p>

                                    </span>

                                </li>
                                <li className="process_footer_title_list list_title_5">
                                    <div><span>5</span></div>

                                    <span className='process_list_txt'>
                                        <strong>테스트/피드백</strong>
                                        <p><span>:</span>오류가 없도록 자체적으로 테스트를 거친 뒤 1차, 2차를 나누어 진행상황을 공유하고 피드백을 받습니다.</p>
                                    </span>
                                </li>
                                <li className="process_footer_title_list list_title_6">
                                    <div><span>6</span></div>

                                    <span className='process_list_txt'>
                                        <strong>최종산출물 전달</strong>
                                        <p><span>:</span>완성도 있는 최종 결과물을 전달드립니다.</p>
                                    </span>
                                </li>
                            </ul>
                        </div>

                        {/* {
                            VW >= 1440 ? (
                                <>
                                    <div className="process_footer_produce_wrap">
                                        <ul>
                                            <li>
                                                <span>:&nbsp;&nbsp;하단에 간단한 문의하기 양식을 통해 3분안에 의뢰가 가능합니다.</span>
                                            </li>
                                            <li>
                                                <span>:&nbsp;&nbsp;DATAFIVE의 전문 컨설턴트가 정확한 커뮤니케이션으로 상담해드립니다.</span>
                                            </li>
                                            <li>
                                                <span>:&nbsp;&nbsp;합리적이고 투명한 비용을 제시해드립니다.</span>
                                            </li>
                                            <li>
                                                <span>:&nbsp;&nbsp;상담한 내용을 토대로 작업 전, 한번 더 승인된 사항을 재확인 후 개발을 진행합니다.</span>
                                            </li>
                                            <li>
                                                <span>:&nbsp;&nbsp;오류가 없도록 자체적으로 테스트를 거친 뒤 1차, 2차를 나누어 진행상황을 공유하고 피드백을 받습니다.</span>
                                            </li>
                                            <li>
                                                <span>:&nbsp;&nbsp;완성도 있는 최종 결과물을 전달드립니다.</span>
                                            </li>
                                        </ul>
                                    </div>

                                </>
                            ) : (
                                <></>
                            )
                        } */}

                    </div>

                </div>
            </section>
        </>
    );
}

export default Process;