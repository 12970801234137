export const valueCheck = (form,chkboxlength) => {
    if (form.name == '') {
        alert('이름을 작성해주세요.')
        return false
    }
    if (form.company == '') {
        alert('회사명을 작성해주세요.')
        return false
    }
    if (form.phone == '') {
        alert('연락처를 작성해주세요.')
        return false
    }
    if (form.email1 == '') {
        alert('이메일을 작성해주세요.')
        return false
    }
    if (form.email2 == '') {
        alert('이메일 주소를 작성해주세요.')
        return false
    }
    // if (form.chk7 && form.check8 === '') {
    //     alert('서비스 내용을 입력해주세요.')
    // }
    // if (form.chk9 && form.check10 === '') {
    //     alert('검색 키워드를 적어주세요.')
    // }
    // if (form.chk11 && form.check12 === '') {
    //     alert('사이트 명과 검색어를 적어주세요.')
    // }
    // if (form.chk21 && form.check22 === '') {
    //     alert('알게된 경로를 적어주세요.')
    // }
    if (form.personal == false) {
        alert('개인정보 및 이용방침을 체크해주세요.')
        return false
    }

    return {
            name: form.name,
            company: form.company,
            phone: form.phone,
            position: form.position,
            email1: form.email1,
            inquiry : form.inquiry,
            email2: form.email2,
            checkLength: chkboxlength,
            check1: form.chk1 ? 'O' : 'X',
            check2: form.chk2 ? 'O' : 'X',
            check3: form.chk3 ? 'O' : 'X',
            check4: form.chk4 ? 'O' : 'X',
            check5: form.chk5 ? 'O' : 'X',
            check6: form.chk6 ? 'O' : 'X',
            check7: form.chk7 ? 'O' : 'X',
            check8: form.check8,
            check9: form.chk9 ? 'O' : 'X',
            check10: form.check10,
            check11: form.chk11 ? 'O' : 'X',
            check12: form.check12,
            check13: form.chk13 ? 'O' : 'X',
            check14: form.chk14 ? 'O' : 'X',
            check15: form.chk15 ? 'O' : 'X',
            check16: form.chk16 ? 'O' : 'X',
            check17: form.chk17 ? 'O' : 'X',
            check18: form.chk18 ? 'O' : 'X',
            check19: form.chk19 ? 'O' : 'X',
            check20: form.chk20 ? 'O' : 'X',
            check21: form.chk21 ? 'O' : 'X',
            check22: form.check22,
            // method: method
        };
}