import React, { useEffect, useRef, useState } from 'react';
import '../css/reset.css';
import '../css/service.css';


const Service = () => {

    const [activeIndex, setActiveIndex] = useState(0);

    const handleMouseEnter = (index) => {
        setActiveIndex(index);
    }

    const [VW, setVW] = useState(window.innerWidth);
    var viewPort = (648 / 768) * VW
    const handleResize = () => {
        setVW(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);



    return (
        <>
            <section className="service_wrap" id="serviceSection" name="serviceSection" >
                <div className="service_inner" >

                    <div className="service_title_wrap">
                        <h3>SERVICE 1.</h3>
                        <strong>데이터파이브 주요 서비스</strong>
                    </div>

                    <div className="service_produce_wrap">
                        <p>DATAFIVE는 고객의 가치를 실현해드립니다.</p>
                        <span>
                            안드로이드 및 아이폰 앱-웹과 서버 개발을 수행하며, 관리자용 사이트도 개발합니다.<br />
                            다양한 스마트 서비스의 구현을 위해 컨설팅, 시스템 분석, 설계, 개발, 유지보수 등을 통해 지속적인 성장이 가능한 체계를 구축하여 제공합니다.
                        </span>
                    </div>

                    <div className="service_content_wrap">
                        <div
                            className={activeIndex == 0 ? 'service_box active' : 'service_box'}
                            onMouseEnter={() => handleMouseEnter(0)}
                        >
                            <div className="box_frame">
                                <span className="box_frame_1"></span>
                            </div>
                            <div className="box_content">
                                <strong>AI챗봇</strong>
                                <ul>
                                    <li>Chat GPT, GPTs, Gemini </li>
                                    <li>요약 및 문서 생성</li>
                                    <li>카카오채널 등 SNS 연결</li>
                                    <li>DB, 웹사이트, 문서 학습</li>
                                </ul>
                            </div>
                        </div>
                        <div
                            className={activeIndex == 1 ? 'service_box active' : 'service_box'}
                            onMouseEnter={() => handleMouseEnter(1)}
                        >
                            <div className="box_frame">
                                <span className="box_frame_2"></span>
                            </div>
                            <div className="box_content">
                                <strong>앱개발</strong>
                                <ul>
                                    <li>React Native </li>
                                    <li>Kotlin</li>
                                    <li>IoT 연동 앱</li>
                                    <li>하이브리드 앱</li>
                                    <li>애드몹 수익형앱</li>
                                </ul>

                            </div>
                        </div>

                        <div
                            className={activeIndex == 2 ? 'service_box active' : 'service_box'}
                            onMouseEnter={() => handleMouseEnter(2)}
                        >
                            <div className="box_frame">
                                <span className="box_frame_3"></span>
                            </div>
                            <div className="box_content">
                                <strong>웹서비스</strong>
                                <ul>
                                    <li>홈페이지 개발  </li>
                                    <li>쇼핑몰 개발 </li>
                                    <li>클라우드 SaaS 개발</li>
                                    <li>부하테스트</li>
                                </ul>

                            </div>
                        </div>

                        <div
                            className={activeIndex == 3 ? 'service_box active' : 'service_box'}
                            onMouseEnter={() => handleMouseEnter(3)}
                        >
                            <div className="box_frame">
                                <span className="box_frame_4"></span>
                            </div>
                            <div className="box_content">
                                <strong>데이터 분석</strong>
                                <ul>
                                    <li>머신러닝/딥러닝 </li>
                                    <li>연합러닝</li>
                                    <li>자연어처리</li>
                                    <li>크롤링</li>
                                    <li>데이터 시각화</li>
                                </ul>
                            </div>
                        </div>


                    </div>

                </div>
            </section>
        </>
    );
}

export default Service;