import React, { useState, useEffect, useRef } from 'react';
import '../css/reset.css';
import '../css/hero.css';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';

import { Autoplay, Navigation, Parallax, Controller, Pagination } from 'swiper/modules';


const Hero = () => {

    const [VW, setVW] = useState(window.innerWidth);
    const swiperRef = useRef(null);
    const [slidesPerView, setSlidesPerView] = useState(1);

    useEffect(() => {
        const handleResize = () => {
            const newSlidesPerView = window.innerWidth >= 1440 ? window.innerWidth / 943 : 1;
            setSlidesPerView(newSlidesPerView);
            setVW(window.innerWidth)

            if (swiperRef.current && swiperRef.current.swiper) {
                swiperRef.current.swiper.update(); // 사이즈 변경 시 Swiper 업데이트
            }
        };

        window.addEventListener('resize', handleResize);

        // 초기 로딩 시 한 번 호출
        handleResize();

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNextSlide = () => {
        if (swiperRef.current && swiperRef.current.swiper) {
            console.log('1')
            swiperRef.current.swiper.slideNext(); // 다음 슬라이드로 이동
        }
    };


    return (
        <>
            <section className="hero_wrap">
                <div className="hero_inner">
                    <Swiper
                        // 해상도
                        ref={swiperRef} // id 비슷한 값.
                        onSwiper={(swiper) => {
                            swiperRef.current = swiper;
                        }}
                        slidesPerView={slidesPerView}
                        spaceBetween={30}
                        centeredSlides={true}
                        loop={true}
                        init={true}
                        autoplay={{
                            delay: 5000,
                            disableOnInteraction: false,
                            pauseOnMouseEnter: true
                        }}
                        pagination={{
                            clickable: true,
                        }}
                        navigation={{
                            nextEl: '.swiper-button-next',
                            prevEl: '.swiper-button-prev'
                        }}
                        modules={[Autoplay, Navigation, Parallax, Controller, Pagination]}
                        className="mySwiper"
                        scrollbar={{ draggable: false }}
                        noSwiping={true}
                        noSwipingClass="swiper-no-swiping"
                    >
                        <SwiperSlide className="swiper-no-swiping">
                            {({ isActive }) => (
                                <div className='hero_content' style={isActive ? { background: '#FFCF3F' } : { background: '#3E3E3E' }}>
                                    {
                                        VW >= 1440 ? (
                                            <>
                                                <div className="hero_content_inner">
                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        데이터파이브의 <span>ONE칙</span>
                                                    </h3>
                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        한번에 하나의 프로젝트만 수행합니다.<br />
                                                        프로젝트를 진정성 있게 수행하기 위한<br />
                                                        데이터파이브의 철학입니다.
                                                    </span>
                                                </div>

                                                <div className="hero_frame_wrap hero_frame_1">
                                                    <span></span>
                                                </div>

                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <>
                                                    </>
                                                )}
                                            </>
                                        ) : VW >= 768 ? (
                                            <>
                                                <div className="hero_content_inner">
                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        데이터파이브의 <span>ONE칙</span>
                                                    </h3>

                                                    <div className="hero_frame_wrap hero_frame_1">
                                                        <span></span>
                                                    </div>

                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        한번에 하나의 프로젝트만 수행합니다.<br />
                                                        프로젝트를 진정성 있게 수행하기 위한<br />
                                                        데이터파이브의 철학입니다.
                                                    </span>
                                                </div>

                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div className="hero_content_inner">
                                                    <div className="hero_frame_wrap hero_frame_1">
                                                        <span></span>
                                                    </div>

                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        데이터파이브의 <span>ONE칙</span>
                                                    </h3>

                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        한번에 하나의 프로젝트만 수행합니다.<br />
                                                        프로젝트를 진정성 있게 수행하기 위한<br />
                                                        데이터파이브의 철학입니다.
                                                    </span>
                                                </div>

                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )
                                    }

                                </div>
                            )}
                        </SwiperSlide>
                        <SwiperSlide className="swiper-no-swiping">
                            {({ isActive }) => (
                                <div className='hero_content' style={isActive ? { background: '#FFCF3F' } : { background: '#3E3E3E' }}>
                                    {
                                        VW >= 1440 ? (
                                            <>
                                                <div className="hero_content_inner">
                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        <span>커뮤니케이션,</span><br />
                                                        스트레스 받지 마세요!
                                                    </h3>
                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        완성도 있는 결과물을 위해,<br />
                                                        정확하게 커뮤니케이션 합니다.
                                                    </span>
                                                </div>

                                                <div className="hero_frame_wrap hero_frame_2">
                                                    <span></span>
                                                </div>

                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        ) : VW >= 768 ? (
                                            <>
                                                <div className="hero_content_inner">
                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        <span>커뮤니케이션,</span><br />
                                                        스트레스 받지 마세요!
                                                    </h3>

                                                    <div className="hero_frame_wrap hero_frame_2">
                                                        <span></span>
                                                    </div>

                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        완성도 있는 결과물을 위해, 정확하게 커뮤니케이션 합니다.
                                                    </span>
                                                </div>

                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div className="hero_content_inner">
                                                    <div className="hero_frame_wrap hero_frame_2">
                                                        <span></span>
                                                    </div>

                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        <span>커뮤니케이션,</span><br />
                                                        스트레스 받지 마세요!
                                                    </h3>

                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        완성도 있는 결과물을 위해,<br />
                                                        정확하게 커뮤니케이션 합니다.
                                                    </span>
                                                </div>

                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )
                                    }

                                </div>
                            )}
                        </SwiperSlide>
                        <SwiperSlide className="swiper-no-swiping">
                            {({ isActive }) => (
                                <div className='hero_content' style={isActive ? { background: '#FFCF3F' } : { background: '#3E3E3E' }}>
                                    {
                                        VW >= 1440 ? (
                                            <>
                                                <div className="hero_content_inner">
                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        <span>Android / IOS</span><br />
                                                        동시개발이 가능합니다.
                                                    </h3>
                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        리액트 네이티브(React Native)가 가능하므로<br />
                                                        보다 합리적인 비용으로 개발합니다.
                                                    </span>
                                                </div>

                                                <div className="hero_frame_wrap hero_frame_3">
                                                    <span></span>
                                                </div>

                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        ) : VW >= 768 ? (
                                            <>
                                                <div className="hero_content_inner">
                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        <span>Android / IOS</span><br />
                                                        동시개발이 가능합니다.
                                                    </h3>

                                                    <div className="hero_frame_wrap hero_frame_3">
                                                        <span></span>
                                                    </div>

                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        리액트 네이티브(React Native)가 가능하므로<br />
                                                        보다 합리적인 비용으로 개발합니다.
                                                    </span>
                                                </div>

                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div className="hero_content_inner">
                                                    <div className="hero_frame_wrap hero_frame_3">
                                                        <span></span>
                                                    </div>

                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        <span>Android / IOS</span><br />
                                                        동시개발이 가능합니다.
                                                    </h3>

                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        리액트 네이티브(React Native)가 가능하므로<br />
                                                        보다 합리적인 비용으로 개발합니다.
                                                    </span>
                                                </div>

                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )
                                    }

                                </div>
                            )}
                        </SwiperSlide>
                        <SwiperSlide className="swiper-no-swiping">
                            {({ isActive }) => (
                                <div className='hero_content' style={isActive ? { background: '#FFCF3F' } : { background: '#3E3E3E' }}>
                                    {
                                        VW >= 1440 ? (
                                            <>
                                                <div className="hero_content_inner">
                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        <span>데이터 구축,</span> 개발 단계에서<br />
                                                        미리 준비하세요!
                                                    </h3>
                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        의사결정에 필요한 데이터 구축을 도와드립니다.<br />
                                                        비즈니스에 도움을 받아보세요.
                                                    </span>
                                                </div>

                                                <div className="hero_frame_wrap hero_frame_4">
                                                    <span></span>
                                                </div>

                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        ) : VW >= 768 ? (
                                            <>
                                                <div className="hero_content_inner">
                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        <span>데이터 구축,</span> 개발 단계에서<br />
                                                        미리 준비하세요!
                                                    </h3>

                                                    <div className="hero_frame_wrap hero_frame_4">
                                                        <span></span>
                                                    </div>

                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        의사결정에 필요한 데이터 구축을 도와드립니다.<br />
                                                        비즈니스에 도움을 받아보세요.
                                                    </span>
                                                </div>


                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div className="hero_content_inner">
                                                    <div className="hero_frame_wrap hero_frame_4">
                                                        <span></span>
                                                    </div>

                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        <span>데이터 구축,</span> 개발 단계에서<br />
                                                        미리 준비하세요!
                                                    </h3>

                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        의사결정에 필요한 데이터 구축을 도와드립니다.<br />
                                                        비즈니스에 도움을 받아보세요.
                                                    </span>
                                                </div>


                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )
                                    }

                                </div>
                            )}
                        </SwiperSlide>
                        <SwiperSlide className="swiper-no-swiping">
                            {({ isActive }) => (
                                <div className='hero_content' style={isActive ? { background: '#FFCF3F' } : { background: '#3E3E3E' }}>
                                    {
                                        VW >= 1440 ? (
                                            <>
                                                <div className="hero_content_inner">
                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        <span>인공지능이 적용된</span><br />
                                                        서비스 개발이 가능합니다.
                                                    </h3>
                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        DATAFIVE는 인공지능 기술을 보유하고 있습니다.<br />
                                                        서비스 개발시 편의성을 올려보세요.
                                                    </span>
                                                </div>

                                                <div className="hero_frame_wrap hero_frame_5">
                                                    <span></span>
                                                </div>

                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        ) : VW >= 768 ? (
                                            <>
                                                <div className="hero_content_inner">
                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        <span>인공지능이 적용된</span><br />
                                                        서비스 개발이 가능합니다.
                                                    </h3>

                                                    <div className="hero_frame_wrap hero_frame_5">
                                                        <span></span>
                                                    </div>

                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        DATAFIVE는 인공지능 기술을 보유하고 있습니다.<br />
                                                        서비스 개발시 편의성을 올려보세요.
                                                    </span>
                                                </div>


                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div className="hero_content_inner">
                                                    <div className="hero_frame_wrap hero_frame_5">
                                                        <span></span>
                                                    </div>

                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        <span>인공지능이 적용된</span><br />
                                                        서비스 개발이 가능합니다.
                                                    </h3>

                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        DATAFIVE는 인공지능 기술을 보유하고 있습니다.<br />
                                                        서비스 개발시 편의성을 올려보세요.
                                                    </span>
                                                </div>


                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )
                                    }

                                </div>
                            )}
                        </SwiperSlide>
                        <SwiperSlide className="swiper-no-swiping">
                            {({ isActive }) => (
                                <div className='hero_content' style={isActive ? { background: '#FFCF3F' } : { background: '#3E3E3E' }}>
                                    {
                                        VW >= 1440 ? (
                                            <>
                                                <div className="hero_content_inner">
                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        <span>AI 챗봇</span>을 이용하여<br />
                                                        <span>실시간 대화형 서비스</span>를 만드세요!
                                                    </h3>
                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        고객님의 학습 데이터를 기반으로 다양한<br />
                                                        사용자의 질문에 대한 정확한 답변을 제공합니다.
                                                    </span>
                                                </div>

                                                <div className="hero_frame_wrap hero_frame_6">
                                                    <span></span>
                                                </div>

                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        ) : VW >= 768 ? (
                                            <>
                                                <div className="hero_content_inner">
                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        <span>AI 챗봇</span>을 이용하여<br />
                                                        <span>실시간 대화형 서비스</span>를 만드세요!
                                                    </h3>

                                                    <div className="hero_frame_wrap hero_frame_6">
                                                        <span></span>
                                                    </div>

                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        고객님의 학습 데이터를 기반으로 다양한<br />
                                                        사용자의 질문에 대한 정확한 답변을 제공합니다.
                                                    </span>
                                                </div>


                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        ) : (
                                            <>
                                                <div className="hero_content_inner">
                                                    <div className="hero_frame_wrap hero_frame_6">
                                                        <span></span>
                                                    </div>

                                                    <h3 style={isActive ? { color: '#1B1B1B' } : { color: '#fff' }}>
                                                        <span>AI 챗봇</span>을 이용하여<br />
                                                        <span>실시간 대화형 서비스</span>를 만드세요!
                                                    </h3>

                                                    <span style={isActive ? { color: '#4E4E4E' } : { color: '#bbb' }}>
                                                        고객님의 학습 데이터를 기반으로 다양한<br />
                                                        사용자의 질문에 대한 정확한 답변을 제공합니다.
                                                    </span>
                                                </div>


                                                {isActive ? (
                                                    <>
                                                        <div className='swiper-button-prev' onClick={() => { swiperRef.current.slidePrev() }}></div>
                                                        <div className='swiper-button-next' onClick={() => { swiperRef.current.slideNext() }}></div>
                                                    </>
                                                ) : (
                                                    <></>
                                                )}
                                            </>
                                        )
                                    }

                                </div>
                            )}
                        </SwiperSlide>
                    </Swiper>
                </div>
            </section>
        </>
    );
}

export default Hero;