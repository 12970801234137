import React from "react";
import '../css/reset.css';
import '../css/main.css';
import Header from './Header';
import Hero from './Hero';
import Service from './Service';
import Service2 from './Service2';
import Process from './Process';
import Portfolio from "./Portfolio";
import About from './About';
import Company from "./Company";
import Contact from "./Contact";
import Footer from "./Footer";

const Main = (prop) => {
    return(
      <>
        <div className="app" id="appSection" name="appSection">
          <Header />
          <Hero />
          <div className="app_content">
            <Service />
            <Service2 />
            <Process />
            <Portfolio />
            <About />
            <Company />
            <Contact />
            <Footer />
          </div>
        </div>
      </>
    );
}

export default Main;