import React, {  useState, useEffect } from 'react';
import '../css/reset.css';
import '../css/service.css';


const Service2 = () => {

    // const [VW, setVW] = useState(window.innerWidth);
    // var viewPort = (648 / 768) * VW
    // const handleResize = () => {
    //     setVW(window.innerWidth);
    // };

    // useEffect(() => {
    //     window.addEventListener("resize", handleResize);
    //     return () => {
    //         window.removeEventListener("resize", handleResize);
    //     };
    // }, []);

    return (
        <>
            <section
                className="service2_wrap"
                id="serviceSection2"
                name="serviceSection2"
            >
                <div className="service2_inner" >

                    <div className="service2_title_wrap">
                        <h3>SERVICE 2.</h3>
                        <strong>서비스 기술</strong>
                    </div>

                    <div className="service2_produce_wrap">
                        <p>데이터파이브는 다양한 보유기술과 여러 운영체제를 지원하고 있습니다.</p>
                    </div>

                    <div className="service2_content_wrap">
                        <div className='list_frame'>
                            <div className='list_frame_title_wrap'>
                                <h4>개발기술</h4>
                            </div>
                            <div className='list_frame_inner'>
                                <ul>
                                    <li ><span>React-native</span></li>
                                    <li ><span>firebase</span></li>
                                    <li ><span>Kotlin</span></li>
                                    <li ><span>nodejs</span></li>
                                    <li ><span>react</span></li>
                                    <li ><span>Django</span></li>
                                    <li ><span>python</span></li>
                                    <li ><span>AWS</span></li>
                                    <li ><span>mySQL</span></li>
                                    <li ><span>Postgresql</span></li>
                                    <li ><span>Chat GPT</span></li>
                                    <li ><span>Gemini</span></li>
                                </ul>
                            </div>
                        </div>

                        <div className="list_frame">
                            <div>
                                <h4>운영체제</h4>
                            </div>
                            <div className='list_frame_inner'>
                                <ul>
                                    <li ><span>Android</span></li>
                                    <li ><span>IOS</span></li>
                                    <li ><span>Linux</span></li>
                                    <li ><span>Window</span></li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>

            </section>
        </>
    );
}

export default Service2;