import React, { useState, useEffect, useCallback } from 'react';
import '../css/reset.css';
import '../css/company.css';


const Company = () => {

    const [VW, setVW] = useState(window.innerWidth);
    const handleResize = () => {
        setVW(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return(
        <>
            <section className="company_wrap" id="companySection" name="companySection">
                <div className="company_inner">
                    
                    <div className="company_title_wrap">
                        <h3>COMPANY</h3>
                        <strong>데이터파이브 & 키온비트</strong>
                    </div>

                    {
                        VW >= 1440 ? (
                            <></>
                        ) : (
                            <>
                                <div className="company_middle_background">
                                    <span></span>
                                </div>
                            </>
                        )
                    }

                    <div
                        className="company_middle_title"
                        style={
                            VW >= 1440 ? {} :
                            VW >= 1000 ? {margin: '0 auto', marginTop: '30px'} : {}
                        }
                    >
                        <h4>
                            Keyonbit<br />
                            ‘Solution We Create, Value We Create’
                        </h4>
                    </div>

                    <div
                        className="company_content"
                        style={
                            VW >= 1440 ? {} :
                            VW >= 1000 ? {margin: '0 auto', marginTop: '30px'} : {}
                        }
                    >
                        {
                            VW >= 768 ? (
                                <>
                                    <p>
                                        (주)키온비트는 IT서비스를 통해 더욱 발전한 물류를 설계하고,<br />
                                        데이터를 기반으로 앞서가는 물류 최적화 기술을 선보입니다.<br />
                                        또한 기업과 물류를 함께 편하고 수월하게 이끌어 나가는 기업입니다.
                                    </p>
                                    <p>
                                        “Be Free World With Technology”<br />
                                        기술을 통해 사람들을 자유롭게 하는 기업.
                                    </p>
                                    <p>
                                        (주)키온비트는 IT서비스를 통해 더욱 발전한 업무환경을 제공합니다.<br />
                                        문제를 더욱 빠르고. 더욱 단순하고, 더욱 정확하게 해결하여,<br />
                                        복잡한 업무에서 벗어나게 하여 새로운 가치를 창출하겠습니다. 
                                    </p>
                                </>
                            ) : (
                                <>
                                    <p>
                                        (주)키온비트는 IT서비스를 통해 더욱 발전한 물류를 설계하고, 
                                        데이터를 기반으로 앞서가는 물류 최적화 기술을 선보입니다.<br />
                                        또한 기업과 물류를 함께 편하고 수월하게 이끌어 나가는 기업입니다.
                                    </p>
                                    <p>
                                        “Be Free World With Technology”<br />
                                        기술을 통해 사람들을 자유롭게 하는 기업.
                                    </p>
                                    <p>
                                        (주)키온비트는 IT서비스를 통해 더욱 발전한 업무환경을 제공합니다.<br />
                                        문제를 더욱 빠르고. 더욱 단순하고, 더욱 정확하게 해결하여, 
                                        복잡한 업무에서 벗어나게 하여 새로운 가치를 창출하겠습니다. 
                                    </p>
                                </>
                            )
                        }
                        
                    </div>

                    <span className="company_background"></span>
                    <span className="company_background2"></span>
                </div>
            </section>
        </>
    );
}

export default Company;