import React, { useState, useEffect, useCallback } from 'react';
import '../css/reset.css';
import '../css/contact.css';
import emailjs from '@emailjs/browser';
import { valueCheck } from '../util/valueCheck';

const Contact = () => {
  const [VW, setVW] = useState(window.innerWidth);
  const [scriptLoaded, setScriptLoaded] = useState(false);
  const handleResize = () => {
    setVW(window.innerWidth);
  };
  const [form, setForm] = useState({
    name: '',
    company: '',
    phone: '',
    position: '',
    email1: '',
    email2: '',
    checkLength: '',
    chk1: false,
    chk2: false,
    chk3: false,
    chk4: false,
    chk5: false,
    chk6: false,
    chk7: false,
    check8: '',
    chk9: false,
    check10: '',
    chk11: false,
    check12: '',
    chk13: false,
    chk14: false,
    chk15: false,
    chk16: false,
    chk17: false,
    chk18: false,
    chk19: false,
    chk20: false,
    chk21: false,
    check22: '',
    inquiry: '',
    personal: false,
  });
  // const [emailSelfWrite, setEmailSelfWrite] = useState(false);

  useEffect(() => {
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const loadScript = (src, callback) => {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.onload = callback;
      document.body.appendChild(script);
    };

    loadScript('//wcs.naver.net/wcslog.js', () => {
      setScriptLoaded(true);
    });
  }, []);

  // 이름 input 태그 정규식. 특수문자, 숫자 방지
  // const [nameVal, setNameVal] = useState('');
  const namecharacterCheck = event => {
    var text = event.target.value;
    var regExp = /[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi; // 특수문자 방지
    var regExp2 = /[0-9]+$/g; // 숫자방지
    if (regExp.test(text) || regExp2.test(text)) {
      text = text.substring(0, text.length - 1); // 입력한 특수문자 한자리 지움
    }
    setForm({ ...form, name: text });
  };

  // 이메일1 특수문자 방지.
  // const [emailVal1, setEmailVal1] = useState('');
  const emailcharacterCheck = event => {
    var text = event.target.value;
    var regExp = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글방지
    var regExp2 = /[ \{\}\[\]\/?.,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi; // 특수문자 방지
    if (regExp.test(text) || regExp2.test(text)) {
      text = text.substring(0, text.length - 1); // 입력한 특수문자 한자리 지움
    }
    setForm({ ...form, email1: text });
  };

  // 전화번호 문자, 특수문자 제거
  // const [phoneVal, setPhoneVal] = useState('');
  function phonecharacterCheck(event) {
    var regExp = /[^0-9]/g;
    var regExp2 = /[\{\}\[\]\/?.,;:|\)*~`!^_+<>@\#$%&\\\=\(\'\"]/gi; // 특수문자 방지
    var text = event.target.value;
    if (regExp.test(text) || regExp2.test(text)) {
      if (regExp2.test(text) == false) {
        text = text;
      } else {
        text = text.substring(0, text.length - 1); // 입력한 특수문자 한자리 지움
      }
    }
    setForm({ ...form, phone: text });
  }

  // const [selectedValue, setSelectedValue] = useState('');
  // const [myText, setMyText] = useState('');
  // 이메일 select 했을시.
  const handleChange = event => {
    // var text = event.target.value
    if (event.target.value == '직접입력') {
      // setEmailSelfWrite(true)
      setForm({ ...form, email2: '' });
      // setForm({...form,email2:''})
      // setMyText('')
      // setSelectedValue('');
    } else {
      // setEmailSelfWrite(false)
      setForm({ ...form, email2: event.target.value });
      // setSelectedValue(event.target.value);
    }
  };

  // 이메일 select으로 직접입력 했을시.
  const handleChangeText = event => {
    var text = event.target.value;
    var regExp = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/; // 한글방지
    var regExp2 = /[ \{\}\[\]\/?,;:|\)*~`!^\-_+┼<>@\#$%&\'\"\\\(\=]/gi; // .을 제외한 특수문자 방지
    if (regExp.test(text) || regExp2.test(text)) {
      text = text.substring(0, text.length - 1); // 입력한 특수문자 한자리 지움
    }
    // setMyText(text)
    setForm({ ...form, email2: event.target.value });
  };

  // 문의종류 기타 체크시 기타입력칸 가능하도록.
  // const [checkVal, setCheckVal] = useState(true);
  // const checkFuc = (event) => {
  //     var target = event.target.checked
  //     setForm({ ...form, chk7: target })
  //     if (target == true) {
  //         setCheckVal(false)
  //     } else {
  //         setCheckVal(true)
  //     }
  //     setCheckText('')
  // }

  // const [checkText, setCheckText] = useState('');
  // const handleCheckVal = (event) => {
  //     var text = event.target.value
  //     setCheckText(text)
  // }

  // 문의하기 버튼 클릭시 이벤트.
  const contactSubmit = async e => {
    e.preventDefault();

    // 고객정보
    // var name = document.querySelector('#user_name').value
    // var company = document.querySelector('#user_company').value
    // var phone = document.querySelector('#user_phone').value
    // var position = document.querySelector('#user_position').value
    // var email1 = document.querySelector('#user_email').value
    // var email2 = document.querySelector('#user_email2').value
    // 문의종류 체크박스
    var chkboxlength = document.querySelectorAll(
      '.type33  input[type="checkbox"]:checked'
    ).length;
    // var chk1 = document.querySelector('#user_select_App').checked
    // var chk2 = document.querySelector('#user_select_Web').checked
    // var chk3 = document.querySelector('#user_select_Data').checked
    // var chk4 = document.querySelector('#user_select_Ai').checked
    // var chk5 = document.querySelector('#user_select_Site').checked
    // var chk6 = document.querySelector('#user_select_shopping').checked
    // var chk7 = document.querySelector('#user_select_etc').checked

    // 문의내용
    // var inquiry = document.querySelector('.user_inquiry_content').value
    // 알게 된 경로
    // var method = document.querySelector('.user_method_content').value
    // 개인정보 / 이용방침 체크유무
    // var personal = document.querySelector('#user_personal_info').checked

    const templateParams = valueCheck(form, chkboxlength);
    if (templateParams) {
      // 1: 서비스아이디, 2: 템플릿 ID, 3: 템플릿데이터, 4: pulic Key.
      emailjs
        .send(
          'service_tuurz8q',
          'template_pyy6jid',
          templateParams,
          'user_VdREZsoaD7r3NOvO9JTIO'
        )
        .then(
          response => {
            console.log('SUCCESS!', response.status, response.text);
            alert('문의 신청을 완료 하였습니다.');

            //20240321 naver log 추가
            // wcs 스크립트가 이미 로드되었는지 확인
            // if (window.wcs) {
            //     var _nasa = {};
            //     _nasa["cnv"] = wcs.cnv("5", "10");
            //     // 추가 로직 구현
            // }
            // 20240520 수정
            // if (!wcs_add) var wcs_add = {};
            // wcs_add["wa"] = "s_2741cfab16ae";
            // var _nasa = {};

            // if (window.wcs) {
            //     _nasa["cnv"] = wcs.cnv("5", "10");
            // } else {
            //     // wcs 스크립트 로드
            //     const script = document.createElement('script');
            //     script.src = "//wcs.naver.net/wcslog.js";
            //     script.onload = () => {
            //         // 스크립트 로드 후 실행할 코드
            //         if (window.wcs) {

            //             var _nasa = {};
            //             _nasa["cnv"] = wcs.cnv("5", "10");
            //         }
            //     };
            //     document.head.appendChild(script);
            // }
            ////////////////

            if (scriptLoaded && window.wcs) {
              if (!window.wcs_add) var wcs_add = {};
              window.wcs_add = { wa: 's_2741cfab16ae' };
              var _nasa = {};
              _nasa['cnv'] = window.wcs.cnv('5', '0');
              window.wcs_do(_nasa);
              console.log('NAVER Analytics log sent:', _nasa);
            }
            //NAVER SCRIPT END

            window.location.reload();
          },
          error => {
            console.log('FAILED...', error);
          }
        );
    }

    //각 요소는 emailJS에서 설정한 템플릿과 동일한 명으로 작성!
    // var templateParams = {
    //     name: form.name,
    //     company: form.company,
    //     phone: form.phone,
    //     position: form.position,
    //     email1: form.email1,
    //     email2: form.email2,
    //     checkLength: chkboxlength,
    //     check1: form.chk1 ? 'O' : 'X',
    //     check2: form.chk2 ? 'O' : 'X',
    //     check3: form.chk3 ? 'O' : 'X',
    //     check4: form.chk4 ? 'O' : 'X',
    //     check5: form.chk5 ? 'O' : 'X',
    //     check6: form.chk6 ? 'O' : 'X',
    //     check7: form.chk7 ? 'O' : 'X',
    //     check8: form.check8,
    //     inquiry: form.inquiry,
    //     // method: method
    // };
    // console.log(form);
  };

  const [modalBg, setModalBg] = useState(false);

  const contactDetailBtn = () => {
    setModalBg(true);
  };

  const contactDetail = e => {
    document.body.style.overflowY = 'hidden';
    // document.body.style.paddingRight = "14px";
    // document.getElementsByClassName('header_inner')[0].style.paddingRight = "15px";

    return (
      <div className='contact_modal_content'>
        <div className='contact_modal_header'>
          <h4>개인정보 수집 및 이용방침</h4>
          <button onClick={closeModal}></button>
        </div>

        <div className='contact_modal_inner'>
          <div className='contact_content_text_wrap'>
            <div className='privacy'>
              <div className='privacy_0'>
                <p>
                  개인정보 수집 및 이용방침 (주)키온비트(이하 “데이터파이브”)는
                  개인정보를 안전하게 보호하기 위하여 "정보통신망 이용촉진 및
                  정보보호 등에 관한 법률"을 비롯한 모든 개인정보보호 규정,
                  가이드라인을 준수하고 있습니다. "개인정보 수집 및
                  이용방침"이란 이용자의 소중한 개인정보를 보호함으로써 이용자가
                  안심하고 서비스를 이용할 수 있도록 데이터파이브가 준수해야 할
                  지침을 의미합니다.
                </p>
              </div>

              <div className='privacy_1'>
                <strong>1. 개인정보 수집 항목 및 목적</strong>
                <p>
                  데이터파이브에서 제공하는 서비스는 서비스를 이용하는 과정에서
                  최소한의 개인정보를 수집하고 있습니다.
                </p>
                <dl>
                  <dt>구분</dt>
                  <dt>수집 항목</dt>
                  <dt>이용 목적</dt>
                </dl>
                <dl>
                  <dd>문의하기(필수)</dd>
                  <dd>이름, 연락처, 이메일, 필요서비스</dd>
                  <dd>고객 상담 접수 및 처리</dd>
                </dl>
                <dl>
                  <dd>문의하기(선택)</dd>
                  <dd>회사명, 직급/소속,일정,의뢰내용</dd>
                  <dd>고객 상담 접수 및 처리</dd>
                </dl>
                <dl>
                  <dd>기타 서비스 이용(자동)</dd>
                  {VW >= 768 ? (
                    <>
                      <dd>
                        서비스 이용기록,접속로그,
                        <br />
                        쿠키,접속 IP정보
                      </dd>
                      <dd>
                        본인 확인 및 서비스 이용 통계,
                        <br />
                        부정이용방지
                      </dd>
                    </>
                  ) : (
                    <>
                      <dd>서비스 이용기록,접속로그,쿠키,접속 IP정보</dd>
                      <dd>본인 확인 및 서비스 이용 통계,부정이용방지</dd>
                    </>
                  )}
                </dl>
              </div>

              <div className='privacy_2'>
                <strong>2. 개인정보 수집 방법</strong>
                <pre>
                  개인정보를 수집하는 경우에는 사전에 이용자에게 해당 사실을
                  알리고 동의를 구하고 있으며, 아래와 같은 법령에 따라 동의 없이
                  이용자의 개인정보를 수집∙이용할수 있습니다.
                  <br />
                  ① 정보통신서비스의 제공에 관한 계약을 이행하기 위하여 필요한
                  개인정보로써 경제적 · 기술적인 사유로 통상적인 동의를 받는
                  것이 뚜렷하게 곤란한 경우
                  <br />
                  ② 부가 서비스 이용 또는 이벤트 응모 과정에서 해당 이용자에게
                  개인정보 추가 수집에 대해 동의를 받는 경우 서비스 이용
                  과정에서 이용자로부터 아래의 방법을 통해 개인정보가 수집될 수
                  있습니다.
                  <br />
                  ① PC웹, 모바일 웹/앱 이용 과정에서 단말기 정보(OS, 화면
                  사이즈, 디바이스 아이디, 휴대폰 기종, 단말기 모델명), IP 주소,
                  쿠키, 방문 일시, 부정 이용 기록, 서비스 이용 기록 등의 정보가
                  자동으로 생성되어 수집될 수 있습니다.
                  <br />② 서비스 이용에 따른 본인 확인 및 개인 식별을 위해 웹
                  페이지 등을 통해 이용자의 개인정보가 수집될 수 있습니다.
                </pre>
              </div>

              <div className='privacy_3'>
                <strong>3. 개인정보 제공 및 위탁</strong>
                <p>
                  데이터파이브는 이용자의 별도 동의가 있는 경우나 법령에 규정된
                  경우를 제외하고는 이용자의 개인정보를 제3자에 제공하지
                  않습니다.
                </p>
              </div>

              <div className='privacy_4'>
                <strong>
                  4. 웹사이트 정보 자동 수집 장치(쿠키)에 대한 사항
                </strong>
                <pre>
                  쿠키는 웹사이트를 운영하는 데 이용하는 서버가 이용자의
                  브라우저로 전송하는 작은 텍스트 파일로, 이용자의 웹사이트 설정
                  상태, 방문 기록, 이용 형태를 자동으로 저장하여 웹사이트 사용을
                  빠르고 편리하도록 도와주는 역할을 합니다. 쿠키는 이름이나
                  전화번호 등 개인을 식별하는 정보를 저장하지 않지만, 이용자
                  스스로 쿠키 자동 수집 여부를 선택할 수 있습니다. [쿠키 수집
                  거부 설정 방법] ① Internet Explorer : 웹 브라우저 상단의 [도구
                  메뉴 &#62; 인터넷 옵션 &#62; 개인정보 &#62; 설정] ② Chrome :
                  웹 브라우저 우측의 [설정 메뉴 &#62; 화면 하단의 고급 설정 표시
                  &#62; 개인정보의 콘텐츠 설정 버튼 &#62; 쿠키]
                </pre>
              </div>

              <div className='privacy_5'>
                <strong>5. 개인정보의 처리 및 이용기간</strong>
                <p>
                  데이터파이브는 원칙적으로 개인정보의 수집 및 이용목적 달성
                  또는 보유기간이 만료되는 경우, 수집된 개인정보는 열람하거나
                  이용할 수 없도록 지체없이 파기 처리합니다. 그 외 관계법령의
                  규정에 의하여 보존할 필요가 있는 경우, 일정한 기간 동안 외부와
                  차단된 별도의 DB 또는 테이블에 분리 보관합니다.
                </p>
                <nav>
                  <dl>
                    <dt>보존 근거</dt>
                    <dt>보존 항목</dt>
                    <dt>보존 기간</dt>
                  </dl>
                  <dl>
                    {VW >= 768 ? (
                      <>
                        <dd>
                          전자상거래등에서의
                          <br />
                          소비자보호에 관한 법률
                        </dd>
                        <dd>
                          소비자 불만 또는 분쟁
                          <br /> 처리에 관한 기록
                        </dd>
                        <dd>3년</dd>
                      </>
                    ) : (
                      <>
                        <dd>전자상거래등에서의 소비자보호에 관한 법률</dd>
                        <dd>소비자 불만 또는 분쟁 처리에 관한 기록</dd>
                        <dd>3년</dd>
                      </>
                    )}
                  </dl>
                  <dl>
                    <dd>통신비밀보호법</dd>
                    <dd>서비스이용관련개인정보</dd>
                    <dd>3개월</dd>
                  </dl>
                </nav>
              </div>

              <div className='privacy_6'>
                <strong>6. 개인정보의 파기방법</strong>
                <p>
                  데이터파이브는 이용자의 개인정보 유출로 인한 피해가 발생하지
                  않도록 전자적 파일형태로 저장된 개인정보는 기록을 재생할 수
                  없는 기술적 방법을 사용하여 삭제하며, 종이에 출력된 개인정보는
                  분쇄기로 분쇄하여 파기합니다.
                </p>
              </div>

              <div className='privacy_7'>
                <strong>7. 이용자의 권리</strong>
                <pre>
                  ① 정보주체는 데이터파이브에 대해 언제든지 다음 각 호의
                  개인정보보호 관련 권리를 행사할 수 있습니다.
                  <br />
                  &#160;&#160;&#160;&#160;&#160;1&#41; 개인정보 열람요구
                  <br />
                  &#160;&#160;&#160;&#160;&#160;2&#41; 오류 등이 있을 경우
                  개인정보 정정 요구
                  <br />
                  &#160;&#160;&#160;&#160;&#160;3&#41; 개인정보 삭제 요구
                  <br />
                  &#160;&#160;&#160;&#160;&#160;4&#41; 개인정보 처리정지 요구
                  <br />
                  ② 제1항에 따른 권리 행사는 데이터파이브에 대해 개인정보보호법
                  시행령 제41조 제1항에 따라 서면, 전자우편, 모사전송(FAX) 등을
                  통하여 하실 수 있으며 데이터파이브는 이에 대해 지체 없이
                  조치하겠습니다.
                  <br />
                  ③ 제1항에 따른 권리 행사는 정보주체의 법정대리인이나 위임을
                  받은 자 등 대리인을 통하여 하실 수 있습니다. 이 경우
                  개인정보보호법 시행규칙 별지 제11호 서식에 따른 위임장을
                  제출하셔야 합니다.
                  <br />
                  ④ 개인정보 열람 및 처리정지 요구는 개인정보보호법 제35조
                  제5항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수
                  있습니다.
                  <br />
                  ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가
                  수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수
                  없습니다.
                  <br />
                  ⑥ 데이터파이브는 정보주체 권리에 따른 열람의 요구, 정정·삭제의
                  요구, 처리정지의 요구 시 열람 등 요구를 한 자가 본인이거나
                  정당한 대리인인지를 확인합니다.
                  <br />
                </pre>
              </div>

              <div className='privacy_8'>
                <strong>8. 개인정보 보호책임자 및 담당자</strong>
                <p>
                  데이터파이브의 서비스를 이용하면서 발생하는 모든 개인정보보호
                  관련 문의, 불만, 조언이나 기타 사항은 개인정보 보호책임자 및
                  담당부서로 연락해 주시기 바랍니다. 데이터파이브는 여러분의
                  목소리에 귀 기울이고 신속하고 충분한 답변을 드릴 수 있도록
                  최선을 다하겠습니다.
                </p>
                <p className='privacy_8_title'>개인정보 보호 책임자</p>
                <nav className='privacy_8_nav1'>
                  <dl>
                    <dt>이름</dt>
                    <dd>이미영</dd>
                  </dl>
                  <dl>
                    <dt>직책</dt>
                    <dd>CEO</dd>
                  </dl>
                  <dl>
                    <dt>이메일</dt>
                    <dd>mylee@keyonbit.com</dd>
                  </dl>
                  <dl>
                    <dt>연락처</dt>
                    <dd>02-6953-0217</dd>
                  </dl>
                </nav>

                <p className='privacy_8_nav2_title'>
                  기타 개인정보침해에 대한 신고나 상담이 필요한 경우 아래 기관에
                  문의하시기 바랍니다.
                </p>
                <nav className='privacy_8_nav2'>
                  <dl>
                    <dt>구분</dt>
                    <dt>웹사이트</dt>
                    <dt>전화번호</dt>
                  </dl>
                  <dl>
                    <dd>개인정보침해</dd>
                    <dd>신고센터 http://privacy.kisa.or.kr</dd>
                    <dd>국번없이 118</dd>
                  </dl>
                  <dl>
                    <dd>대검찰청</dd>
                    <dd>사이버수사과 http://www.spo.go.kr</dd>
                    <dd>국번없이 1301</dd>
                  </dl>
                  <dl>
                    <dd>경찰청</dd>
                    <dd>
                      사이버안전국
                      <br />
                      http://cyberbureau.police.go.kr
                    </dd>
                    <dd>국번없이 182</dd>
                  </dl>
                </nav>
              </div>

              <div className='privacy_9'>
                <strong>9. 개인정보처리방침의 적용 범위</strong>
                <p>
                  데이터파이브의 개인정보처리방침은 데이터파이브
                  웹사이트(datafiveco.kr)에 한해 적용됩니다. 데이터파이브에서
                  제공하는 다른 회사의 웹사이트 링크로 이동하였을 경우, 해당
                  웹사이트의 개인정보처리방침이 적용되니 새로 방문한 웹사이트의
                  정책을 반드시 검토하시기 바랍니다.
                </p>
              </div>

              <div className='privacy_10'>
                <strong>10. 개인정보처리방침의 변</strong>
                <pre>
                  데이터파이브의 개인정보처리방침은 데이터파이브
                  웹사이트(datafive.co.kr)에 공개하여 누구나 쉽게 열람할 수
                  있도록 하고 있습니다. 법률이나 서비스의 변경사항을 반영하기
                  위한 목적 등으로 개인정보취급방침을 수정할 경우, 변경사항을
                  게시하며, 변경된 개인정보처리방침은 게시한 날로부터 7일 후부터
                  효력이 발생합니다.
                  <br />
                  시행일자 : 2023년 11월 15일
                  <br />※ 귀하께서는 귀하의 개인정보 수집 및 이용에 대한 동의를
                  거부하실 권리가 있으며, 동의를 거부하실 경우 서비스 이용에
                  제한이 있을 수 있습니다.
                </pre>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const closeModal = () => {
    setModalBg(false);
    document.body.style.overflowY = 'auto';
    // document.body.style.paddingRight = "0";
    // document.getElementsByClassName('header_inner')[0].style.paddingRight = "0";
  };

  return (
    <>
      <section
        className='contact_wrap'
        id='contactSection'
        name='contactSection'
      >
        <div className='contact_inner'>
          <div className='contact_title_wrap'>
            <h3>CONTACT</h3>
            <strong>데이터파이브에 문의하기</strong>
          </div>

          <div className='contact_content_wrap'>
            <div className='contact_img_wrap'>
              <span></span>
            </div>

            <div className='contact_main_wrap'>
              <div className='contact_user_info_wrap'>
                <div className='contact_user_title_wrap1'>
                  <strong>1. 고객정보</strong>
                </div>

                <div className='contact_input_wrap type1'>
                  <ul>
                    <li>
                      <div>
                        <span>*</span>
                        <label htmlFor='user_name'>이름</label>
                      </div>
                      <div>
                        <input
                          id='user_name'
                          type='text'
                          placeholder='홍길동'
                          tabIndex={1}
                          maxLength={10}
                          title='성함'
                          autoComplete='off'
                          onChange={namecharacterCheck}
                          value={form.name}
                          required
                        />
                      </div>
                    </li>

                    <li>
                      <div>
                        <span>*</span>
                        <label htmlFor='user_company'>회사명</label>
                      </div>
                      <div>
                        <input
                          id='user_company'
                          type='text'
                          placeholder='데이터파이브'
                          tabIndex={1}
                          maxLength={30}
                          title='회사명'
                          autoComplete='off'
                          required
                          onChange={e =>
                            setForm({ ...form, company: e.target.value })
                          }
                          value={form.company}
                        />
                      </div>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <div>
                        <span>*</span>
                        <label htmlFor='user_phone'>연락처</label>
                      </div>
                      <div>
                        <input
                          id='user_phone'
                          type='text'
                          placeholder='010-1234-5678'
                          tabIndex={1}
                          maxLength={13}
                          onChange={phonecharacterCheck}
                          value={form.phone}
                          required
                        />
                      </div>
                    </li>

                    <li>
                      <div>
                        <label htmlFor='user_position'>소속/직책</label>
                      </div>
                      <div>
                        <input
                          id='user_position'
                          type='text'
                          placeholder='마케팅팀/대리'
                          tabIndex={1}
                          maxLength={30}
                          title='직급/소속'
                          autoComplete='off'
                          required
                          value={form.position}
                          onChange={e =>
                            setForm({ ...form, position: e.target.value })
                          }
                        />
                      </div>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <div>
                        <span>*</span>
                        <label htmlFor='user_email'>이메일</label>
                      </div>
                      <div className='user_email_wrap'>
                        <input
                          id='user_email'
                          type='text'
                          tabIndex={1}
                          autoComplete='off'
                          onChange={emailcharacterCheck}
                          value={form.email1}
                        />
                        <div>
                          <label
                            htmlFor='user_email2'
                            className='user_email2 blind'
                          >
                            이메일주소
                          </label>
                          <input
                            id='user_email2'
                            type='text'
                            tabIndex={1}
                            autoComplete='off'
                            onChange={handleChangeText}
                            value={form.email2}
                          />
                        </div>
                        <select
                          id='user_email_list'
                          tabIndex={1}
                          defaultValue='이메일을 선택해주세요.'
                          onChange={handleChange}
                        >
                          <option value=''>이메일을 선택해주세요.</option>
                          <option value='naver.com'>naver.com</option>
                          <option value='gmail.com'>gmail.com</option>
                          <option value='daum.com'>daum.com</option>
                          <option value='nate.com'>nate.com</option>
                          <option value='' className='user_write_email'>
                            직접입력
                          </option>
                        </select>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className='user_select_wrap'>
                <div className='contact_user_title_wrap2'>
                  <strong>2. 문의종류(중복가능)</strong>
                </div>

                <div className='contact_input_wrap type2 type33'>
                  <ul>
                    <li>
                      <input
                        type='checkbox'
                        id='user_select_App'
                        value={form.chk1}
                        onChange={e =>
                          setForm({ ...form, chk1: e.target.checked })
                        }
                      />
                      <label htmlFor='user_select_App'>앱개발</label>
                    </li>

                    <li>
                      <input
                        type='checkbox'
                        id='user_select_Web'
                        value={form.chk2}
                        onChange={e =>
                          setForm({ ...form, chk2: e.target.checked })
                        }
                      />
                      <label htmlFor='user_select_Web'>웹개발</label>
                    </li>

                    <li>
                      <input
                        type='checkbox'
                        id='user_select_Data'
                        value={form.chk3}
                        onChange={e =>
                          setForm({ ...form, chk3: e.target.checked })
                        }
                      />
                      <label htmlFor='user_select_Data'>데이터 분석</label>
                    </li>

                    <li>
                      <input
                        type='checkbox'
                        id='user_select_Ai'
                        value={form.chk4}
                        onChange={e =>
                          setForm({ ...form, chk4: e.target.checked })
                        }
                      />
                      <label htmlFor='user_select_Ai'>AI 바우처</label>
                    </li>
                  </ul>

                  <ul>
                    <li>
                      <input
                        type='checkbox'
                        id='user_select_Site'
                        value={form.chk5}
                        onChange={e =>
                          setForm({ ...form, chk5: e.target.checked })
                        }
                      />
                      <label htmlFor='user_select_Site'>사이트 구축</label>
                    </li>

                    <li>
                      <input
                        type='checkbox'
                        id='user_select_shopping'
                        value={form.chk6}
                        onChange={e =>
                          setForm({ ...form, chk6: e.target.checked })
                        }
                      />
                      <label htmlFor='user_select_shopping'>쇼핑몰 구축</label>
                    </li>

                    <li className='user_select_etc_wrap'>
                      <div>
                        <input
                          type='checkbox'
                          id='user_select_etc'
                          value={form.chk7}
                          onChange={e =>
                            setForm({ ...form, chk7: e.target.checked })
                          }
                        />
                        <label htmlFor='user_select_etc'>기타</label>
                      </div>
                      <div>
                        <label
                          htmlFor='user_select_etc_text'
                          className='blind'
                        ></label>
                        <input
                          type='textbox'
                          id='user_select_etc_text'
                          placeholder='서비스 내용'
                          disabled={!form.chk7}
                          onChange={e =>
                            setForm({ ...form, check8: e.target.value })
                          }
                          value={form.chk7 ? form.check8 : ''}
                          tabIndex={1}
                        />
                      </div>
                    </li>
                  </ul>
                </div>
              </div>

              <div className='user_inquiry_wrap'>
                <div className='contact_user_title_wrap3'>
                  <strong>3. 문의 내용</strong>
                </div>

                <div className='contact_input_wrap type3'>
                  <textarea
                    className='user_inquiry_content'
                    placeholder='예상 개발 비용과 개발 기간을 작성해 주시면 상담에 더욱 도움이 됩니다.'
                    tabIndex={1}
                    autoComplete='off'
                    title='내용'
                    value={form.inquiry}
                    onChange={e =>
                      setForm({ ...form, inquiry: e.target.value })
                    }
                  />
                </div>
              </div>

              <div className='user_method_wrap'>
                <div className='contact_user_title_wrap4'>
                  <strong>4. 알게 된 경로(중복가능)</strong>
                  {VW >= 768 ? (
                    <></>
                  ) : (
                    <>
                      <button onClick={contactDetailBtn}>전문보기</button>
                    </>
                  )}
                </div>

                {/* { <div className="contact_input_wrap type4">
                                    <textarea className="user_method_content" tabIndex={1} autoComplete="off" title="알게된경로"></textarea>
                                </div> } */}
                {
                  <div className='contact_input_wrap type2 type5'>
                    <ul>
                      <li className='user_select_etc_wrap user_select_etc_wrap-w100'>
                        <div>
                          <input
                            type='checkbox'
                            id='user_path_search_naver'
                            onChange={e =>
                              setForm({ ...form, chk9: e.target.checked })
                            }
                            value={form.chk9}
                          />
                          <label htmlFor='user_path_search_naver'>
                            네이버 검색
                          </label>
                        </div>
                        <div>
                          <label
                            htmlFor='user_path_search_naver_text'
                            className='blind'
                          ></label>
                          <input
                            type='textbox'
                            id='user_path_search_naver_text'
                            placeholder='검색 키워드를 적어주세요.'
                            disabled={!form.chk9}
                            onChange={e =>
                              setForm({ ...form, check10: e.target.value })
                            }
                            value={form.chk9 ? form.check10 : ''}
                            tabIndex={1}
                          />
                        </div>
                      </li>
                    </ul>
                    <ul>
                      <li className='user_select_etc_wrap user_select_etc_wrap-w100'>
                        <div>
                          <input
                            type='checkbox'
                            id='user_path_search_another'
                            onChange={e =>
                              setForm({ ...form, chk11: e.target.checked })
                            }
                            value={form.chk11}
                          />
                          <label htmlFor='user_path_search_another'>
                            네이버 외 사이트 검색
                          </label>
                        </div>
                        <div>
                          <label
                            htmlFor='user_path_search_another_text'
                            className='blind'
                          ></label>
                          <input
                            type='textbox'
                            id='user_path_search_another_text'
                            placeholder='사이트 명 / 검색어'
                            disabled={!form.chk11}
                            onChange={e =>
                              setForm({ ...form, check12: e.target.value })
                            }
                            value={form.chk11 ? form.check12 : ''}
                            tabIndex={1}
                          />
                        </div>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <input
                          type='checkbox'
                          id='user_path_datafive_blog'
                          onChange={e =>
                            setForm({ ...form, chk13: e.target.checked })
                          }
                          value={form.chk13}
                        />
                        <label htmlFor='user_path_datafive_blog'>
                          데이터파이브 블로그
                        </label>
                      </li>

                      <li>
                        <input
                          type='checkbox'
                          id='user_path_youtube'
                          onChange={e =>
                            setForm({ ...form, chk14: e.target.checked })
                          }
                          value={form.chk14}
                        />
                        <label htmlFor='user_path_youtube'>유튜브</label>
                      </li>
                      <li>
                        <input
                          type='checkbox'
                          id='user_path_instagram'
                          onChange={e =>
                            setForm({ ...form, chk15: e.target.checked })
                          }
                          value={form.chk15}
                        />
                        <label htmlFor='user_path_instagram'>인스타그램</label>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <input
                          type='checkbox'
                          id='user_path_news'
                          onChange={e =>
                            setForm({ ...form, chk16: e.target.checked })
                          }
                          value={form.chk16}
                        />
                        <label htmlFor='user_path_news'>언론/보도자료</label>
                      </li>

                      <li>
                        <input
                          type='checkbox'
                          id='user_path_show'
                          onChange={e =>
                            setForm({ ...form, chk17: e.target.checked })
                          }
                          value={form.chk17}
                        />
                        <label htmlFor='user_path_show'>전시회</label>
                      </li>
                      <li>
                        <input
                          type='checkbox'
                          id='user_path_friend'
                          onChange={e =>
                            setForm({ ...form, chk18: e.target.checked })
                          }
                          value={form.chk18}
                        />
                        <label htmlFor='user_path_friend'>지인소개</label>
                      </li>
                    </ul>
                    <ul>
                      <li>
                        <input
                          type='checkbox'
                          id='user_path_email'
                          onChange={e =>
                            setForm({ ...form, chk19: e.target.checked })
                          }
                          value={form.chk19}
                        />
                        <label htmlFor='user_path_email'>이메일 광고</label>
                      </li>
                      <li>
                        <input
                          type='checkbox'
                          id='user_path_banner'
                          onChange={e =>
                            setForm({ ...form, chk20: e.target.checked })
                          }
                          value={form.chk20}
                        />
                        <label htmlFor='user_path_banner'>배너광고</label>
                      </li>

                      <li className='user_select_etc_wrap'>
                        <div>
                          <input
                            type='checkbox'
                            id='user_else_path'
                            onChange={e =>
                              setForm({ ...form, chk21: e.target.checked })
                            }
                            value={form.chk21}
                          />
                          <label htmlFor='user_else_path'>기타</label>
                        </div>
                        <div>
                          <label
                            htmlFor='user_else_path_text'
                            className='blind'
                          ></label>
                          <input
                            type='textbox'
                            id='user_else_path_text'
                            placeholder='알게된 경로'
                            disabled={!form.chk21}
                            onChange={e =>
                              setForm({ ...form, check22: e.target.value })
                            }
                            value={form.chk21 ? form.check22 : ''}
                            tabIndex={1}
                          />
                        </div>
                      </li>
                    </ul>
                  </div>
                }

                <div className='user_personal_info_wrap'>
                  <input
                    type='checkbox'
                    id='user_personal_info'
                    tabIndex={1}
                    onChange={e =>
                      setForm({ ...form, personal: e.target.checked })
                    }
                    value={form.personal}
                  />
                  <label htmlFor='user_personal_info'>
                    <span>*</span>(필수) 개인정보 수집 및 이용방침에 동의합니다.
                  </label>
                  {VW >= 768 ? (
                    <>
                      <button onClick={contactDetailBtn}>전문보기</button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </div>

              <div className='user_submit_wrap'>
                <button onClick={contactSubmit}>
                  <span></span>
                  문의하기
                </button>
              </div>
            </div>

            <div className={modalBg == true ? 'contact_modal_wrap' : 'blind'}>
              {modalBg == true ? contactDetail() : <div />}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
