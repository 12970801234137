import React, { useEffect, useState, useRef, useCallback } from 'react';
import { Link } from 'react-scroll';
import '../css/reset.css';
import '../css/header.css';
import Logo from '../img/common/datafive_logo.png';
import * as ChannelService from '@channel.io/channel-web-sdk-loader';   // 채널톡

// 채널톡
ChannelService.loadScript()
ChannelService.boot({
    pluginKey: '94596b74-eb76-4925-8600-2b217d4457d0'
})

// const Header = () => {
//     함수 ㅁ어어어어

//     return (
//         <>
//             <div>
//                 <p>

//                 </p>
//                 <section></section>
//             </div>
//         </>
//     );
// }

// export default Header;

const Header = () => {

    const sectionRef = useRef();

    // const handleClickOutside = (event) => {
    //     // 클릭한 요소가 header_wrap 내부에 있는지 확인
    //     if (sectionRef.current && !sectionRef.current.contains(event.target)) {
    //       navOff()
    //     }
    // };
    const handleClickOutside = useCallback((event) => {
        if (sectionRef.current && !sectionRef.current.contains(event.target)) {
          // 특정 동작 수행
          navOff()
        }
    }, []);

    useEffect(() => {
        const eventName = 'ontouchstart' in window ? 'touchstart' : 'click';
      
        const handleInteraction = (event) => {
          handleClickOutside(event);
        };
      
        // 전체 문서에 이벤트 핸들러 등록
        document.addEventListener(eventName, handleInteraction);
      
        // 컴포넌트가 언마운트될 때 이벤트 핸들러 제거
        return () => {
          document.removeEventListener(eventName, handleInteraction);
        };
    }, [handleClickOutside]);

    const activeStyle = {
        color: '#4E4E4E',
        fontSize: '20px',
        fontWeight: '600',
        lineHeight: 'normal',
        letterSpacing: '-0.5px',
        cursor: 'pointer'
    }
    
    const [position, setPosition] = useState(0);
    function onScroll() {
        setPosition(window.scrollY);
    }

    useEffect(() => {
        window.addEventListener("scroll", onScroll);
        return () => {
          window.removeEventListener("scroll", onScroll);
        };
    }, []);

    const navOn = () => {
        var nav = document.querySelector('.header_tablet_phone .header_nav_wrap')
        nav.classList.add('on')
    }

    const navOff = () => {
        var nav = document.querySelector('.header_tablet_phone .header_nav_wrap')
        nav.classList.remove('on')
    }

    return(
        <>
            <section
                ref={sectionRef}
                className="header_wrap"
                style={position === 0 ? {boxShadow: 'none'} : {boxShadow: '0px 5px 10px 0px rgba(0, 0, 0, 0.15)'}}
            >
                <div className="header_inner">
                    <div className="header_web">
                        <div className="header_logo_wrap">
                            <Link
                                activeClass="active"
                                to="appSection"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={1000}
                            >
                                <button>
                                    <img src={Logo} alt="logo" />
                                </button>
                            </Link>
                        </div>
                        <div className="header_gnb_wrap">
                            <ul>
                                <li>
                                    <Link
                                        activeClass="active"
                                        to="serviceSection"
                                        spy={true}
                                        smooth={true}
                                        offset={-80}
                                        duration={1000}
                                        style={activeStyle}
                                    >
                                        Service
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        activeClass="active"
                                        to="processSection"
                                        spy={true}
                                        smooth={true}
                                        offset={-120}
                                        duration={1000}
                                        style={activeStyle}
                                    >
                                        Process
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        activeClass="active"
                                        to="portfolioSection"
                                        spy={true}
                                        smooth={true}
                                        offset={-120}
                                        duration={1000}
                                        style={activeStyle}
                                    >
                                        Portfolio
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        activeClass="active"
                                        to="aboutSection"
                                        spy={true}
                                        smooth={true}
                                        offset={-120}
                                        duration={1000}
                                        style={activeStyle}
                                    >
                                        About
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        activeClass="active"
                                        to="companySection"
                                        spy={true}
                                        smooth={true}
                                        offset={-120}
                                        duration={1000}
                                        style={activeStyle}
                                    >
                                        Company
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        activeClass="active"
                                        to="contactSection"
                                        spy={true}
                                        smooth={true}
                                        offset={-120}
                                        duration={1000}
                                        style={activeStyle}
                                    >
                                        Contact
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>

                    <div className="header_tablet_phone">
                        <div className="header_logo_wrap">
                            <Link
                                activeClass="active"
                                to="appSection"
                                spy={true}
                                smooth={true}
                                offset={-150}
                                duration={1000}
                            >
                                <button onClick={navOff}>
                                    <img src={Logo} alt="navOff" />
                                </button>
                            </Link>
                        </div>

                        <button className="menu_bar" onClick={navOn}></button>


                        {/* 메뉴 햄버거바 클릭시 나오는 navigation. */}
                        <div className="header_nav_wrap">
                            <button className="header_nav_close_btn" onClick={navOff}></button>
                            <ul>
                                <li>
                                    <Link
                                        activeClass="active"
                                        to="appSection"
                                        spy={true}
                                        smooth={true}
                                        offset={-150}
                                        duration={1000}
                                    >
                                        <button className="header_home" onClick={navOff}><span></span>Home</button>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        activeClass="active"
                                        to="serviceSection"
                                        spy={true}
                                        smooth={true}
                                        offset={-50}
                                        duration={1000}
                                        style={activeStyle}
                                    >
                                        <button onClick={navOff}>Service</button>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        activeClass="active"
                                        to="processSection"
                                        spy={true}
                                        smooth={true}
                                        offset={-90}
                                        duration={1000}
                                        style={activeStyle}
                                    >
                                        <button onClick={navOff}>Process</button>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        activeClass="active"
                                        to="portfolioSection"
                                        spy={true}
                                        smooth={true}
                                        offset={-90}
                                        duration={1000}
                                        style={activeStyle}
                                    >
                                        <button onClick={navOff}>Portfolio</button>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        activeClass="active"
                                        to="aboutSection"
                                        spy={true}
                                        smooth={true}
                                        offset={-90}
                                        duration={1000}
                                        style={activeStyle}
                                    >
                                        <button onClick={navOff}>About</button>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        activeClass="active"
                                        to="companySection"
                                        spy={true}
                                        smooth={true}
                                        offset={-90}
                                        duration={1000}
                                        style={activeStyle}
                                    >
                                        <button onClick={navOff}>Company</button>
                                    </Link>
                                </li>
                                <li>
                                    <Link
                                        activeClass="active"
                                        to="contactSection"
                                        spy={true}
                                        smooth={true}
                                        offset={-90}
                                        duration={1000}
                                        style={activeStyle}
                                    >
                                        <button onClick={navOff}>Contact</button>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Header;