import React, { useState, useEffect, useCallback } from 'react';
import '../css/reset.css';
import '../css/about.css';


const About = () => {

    const [VW, setVW] = useState(window.innerWidth);
    const handleResize = () => {
        setVW(window.innerWidth);
    };

    useEffect(() => {
        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, []);

    return(
        <>
            <section
                className="about_wrap"
                id="aboutSection"
                name="aboutSection"
                style={
                    VW >= 1440 ? {} : 
                    VW >= 1125 ? {height: '1460px'} :
                    VW >= 925 ? {height: '1770px'} :
                    VW >= 768 ? {height: '2053px'} : {}
                }
            >
                <div className="about_inner">
                    
                    <div className="about_title_wrap">
                        <h3>ABOUT</h3>
                        <strong>데이터파이브는?</strong>
                    </div>

                    <div className='about_content_wrap'>
                        <div className='about_logo_content_wrap'>
                            <div className="about_logo">
                                <span></span>
                            </div>

                            <div className="about_corporation_produce">
                                <strong>가치를 확신으로 만들어드립니다.</strong>
                                {
                                    VW >= 1440 ? (
                                        <>
                                            <span>데이터파이브와 비즈니스 파트너가<br />되어보세요!</span>
                                        </>
                                    ) : (
                                        <>
                                            <span>데이터파이브와 비즈니스 파트너가 되어보세요!</span>
                                        </>
                                    )
                                }

                                {
                                    VW >= 1440 ? (
                                        <>
                                            <p>
                                                데이터와 하이파이브의 뜻을 합쳐 만들어진 데이터파이브는<br />
                                                (주)키온비트의 개발용역 사이트입니다.
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <p>
                                                데이터와 하이파이브의 뜻을 합쳐 만들어진 데이터파이브는
                                                (주)키온비트의 개발용역 사이트입니다.
                                            </p>
                                        </>
                                    )
                                }

                                {
                                    VW >= 1440 ? (
                                        <>
                                            <p>
                                                우리는 고객의 니즈에 맞춰 앱 및 앱 개발을 수행하며, 정확하고<br />
                                                신뢰할 수 있는 데이터 수집 및 분석을 통해 고객의 의사결정에<br />
                                                핵심적인 지표를 제공합니다.
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <p>
                                                우리는 고객의 니즈에 맞춰 앱 및 앱 개발을 수행하며, 정확하고
                                                신뢰할 수 있는 데이터 수집 및 분석을 통해 고객의 의사결정에
                                                핵심적인 지표를 제공합니다.
                                            </p>
                                        </>
                                    )
                                }

                                {
                                    VW >= 1440 ? (
                                        <>
                                            <p>
                                                또한 합리적인 비용 구성과 예산 최적화를 중시하는 프로젝트 진행은,<br />
                                                기능적 중요도에 따라 핵심 기능을 강조하여 진행합니다.<br />
                                                우리는 단순한 제작 뿐만 아니라 프로젝트 수행 도중에도 고객과<br />
                                                긴밀히 협력하여 더 나은 결과 도출을 위한 노력을 기울이고 있습니다.
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <p>
                                                또한 합리적인 비용 구성과 예산 최적화를 중시하는 프로젝트 진행은,
                                                기능적 중요도에 따라 핵심 기능을 강조하여 진행합니다.
                                                우리는 단순한 제작 뿐만 아니라 프로젝트 수행 도중에도 고객과
                                                긴밀히 협력하여 더 나은 결과 도출을 위한 노력을 기울이고 있습니다.
                                            </p>
                                        </>
                                    )
                                }
                                
                                {
                                    VW >= 1440 ? (
                                        <>
                                            <p>
                                                고객과의 단기적인 일회성의 관계보다는 지속적인 협력을 통해 함께<br />
                                                성장하는 중요한 파트너십을 구축하고자 합니다.
                                            </p>
                                        </>
                                    ) : (
                                        <>
                                            <p>
                                                고객과의 단기적인 일회성의 관계보다는 지속적인 협력을 통해 함께
                                                성장하는 중요한 파트너십을 구축하고자 합니다.
                                            </p>
                                        </>
                                    )
                                }
                                
                                
                            </div>
                        </div>

                        <div className="about_box_wrap">
                            {
                                VW >= 768 ? (
                                    <>
                                        <ul>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_1">
                                                    <span></span>
                                                </div>
                                                <strong>PC</strong>
                                                <span>
                                                    컴퓨터 관련<br />
                                                    기술적 지원을<br />
                                                    제공하고 지원
                                                </span>
                                            </li>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_2">
                                                    <span></span>
                                                </div>
                                                <strong>WEB</strong>
                                                <span>
                                                    인터넷을 통해<br />
                                                    제공되는 온라인 기능,<br />
                                                    정보를 제공
                                                </span>
                                            </li>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_3">
                                                    <span></span>
                                                </div>
                                                <strong>APP</strong>
                                                <span>
                                                    모바일 기기에 설치해<br />
                                                    다양한 기능과<br />
                                                    서비스를 개발
                                                </span>
                                            </li>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_4">
                                                    <span></span>
                                                </div>
                                                <strong>SOFTWARE</strong>
                                                <span>
                                                    원하는 작업을 수행하기<br />
                                                    위해 사용되는 프로그램과<br />
                                                    데이터 지원
                                                </span>
                                            </li>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_5">
                                                    <span></span>
                                                </div>
                                                <strong>DEVELOP</strong>
                                                <span>
                                                    조직이나 개인이<br />
                                                    시스템 등을 설계하고<br />
                                                    만들어내는 서비스
                                                </span>
                                            </li>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_6 about_icon_bottom_wrap">
                                                    <span></span>
                                                </div>
                                                <strong>BIGDATA</strong>
                                                <span>
                                                    다양한 분야에서<br />
                                                    유용한 정보를<br />
                                                    추출하고 활용
                                                </span>
                                            </li>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_7 about_icon_bottom_wrap">
                                                    <span></span>
                                                </div>
                                                <strong>ANALYSIS</strong>
                                                <span>
                                                    데이터를 조사, 해석<br />
                                                    유용한 정보와<br />
                                                    인사이트를 도출
                                                </span>
                                            </li>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_8 about_icon_bottom_wrap">
                                                    <span></span>
                                                </div>
                                                <strong>INSIGHT</strong>
                                                <span>
                                                    데이터 분석을 통해<br />
                                                    의사 결정과<br />
                                                    전략 수립에 도움
                                                </span>
                                            </li>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_9 about_icon_bottom_wrap">
                                                    <span></span>
                                                </div>
                                                <strong>VISUALIZE</strong>
                                                <span>
                                                    데이터를 쉽게 이해하고<br />
                                                    시각적으로 파악할 수<br />
                                                    있게 해주는 것
                                                </span>
                                            </li>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_10 about_icon_bottom_wrap">
                                                    <span></span>
                                                </div>
                                                <strong>CONSULTING</strong>
                                                <span>
                                                    조직이나 개인이<br />
                                                    목표를 달성, 해결하는데<br />
                                                    도움을 주는 것
                                                </span>
                                            </li>
                                        </ul>
                                    </>
                                ) : (
                                    <>
                                        <ul>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_1">
                                                    <span></span>
                                                </div>
                                                <strong>PC</strong>
                                            </li>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_2">
                                                    <span></span>
                                                </div>
                                                <strong>WEB</strong>
                                            </li>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_3">
                                                    <span></span>
                                                </div>
                                                <strong>APP</strong>
                                            </li>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_4">
                                                    <span></span>
                                                </div>
                                                <strong>SOFTWARE</strong>
                                            </li>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_5">
                                                    <span></span>
                                                </div>
                                                <strong>DEVELOP</strong>
                                            </li>
                                        </ul>

                                        <ul>
                                        <li>
                                                <div className="about_box_img_wrap about_icon_6 about_icon_bottom_wrap">
                                                    <span></span>
                                                </div>
                                                <strong>BIGDATA</strong>
                                            </li>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_7 about_icon_bottom_wrap">
                                                    <span></span>
                                                </div>
                                                <strong>ANALYSIS</strong>
                                            </li>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_8 about_icon_bottom_wrap">
                                                    <span></span>
                                                </div>
                                                <strong>INSIGHT</strong>
                                            </li>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_9 about_icon_bottom_wrap">
                                                    <span></span>
                                                </div>
                                                <strong>VISUALIZE</strong>
                                            </li>
                                            <li>
                                                <div className="about_box_img_wrap about_icon_10 about_icon_bottom_wrap">
                                                    <span></span>
                                                </div>
                                                <strong>CONSULTING</strong>
                                            </li>
                                        </ul>
                                    </>
                                )
                            }
                            
                        </div>
                    </div>

                </div>
            </section>
        </>
    );
}

export default About;